import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Chart } from 'node_modules/Chart.js';
import { ConfigTri } from 'src/app/models/ConfigTri.model';
import { cumulConfigTri } from 'src/app/models/cumulConfigTri.model';
import { ConfigDataService } from '../services/config-data.service';


@Component({
	selector: 'app-graph-cumul',
	templateUrl: './graph-cumul.component.html',
	styleUrls: ['./graph-cumul.component.scss']
})
export class GraphCumulComponent implements OnChanges {

	@Input() tabConfigTri: cumulConfigTri[];
	@Input() client_configs: ConfigTri[];

	@Input() command: number[];
	@Input() Dates: string[];
	@Input() configArray: ConfigTri[];

	oldhideButton: boolean;
	@Input() hideButton: boolean;
	hideConfig_ind: number;

	command_cumul: number[];
	graph_configs: ConfigTri[]; //contient les configs présentes sur le graph

	graphConfigCumul: Chart;
	Config_PieChart: Chart;
	pieChart_configs: ConfigTri[];

	@Input() boolShowGraphCumul: boolean;


	constructor(private configdataService: ConfigDataService) {
		this.configdataService.oldhideButtonSubject.asObservable().subscribe(
			data => this.oldhideButton = data
		);
		this.configdataService.hideConfig_indSubject.asObservable().subscribe(
			data => this.hideConfig_ind = data
		);
	}

	ngOnChanges(changes: SimpleChanges) {

		if (this.boolShowGraphCumul == true) {
			document.getElementById("graphCumul").style.display = 'block';
		}
		else {
			document.getElementById("graphCumul").style.display = 'none';
		}

		//console.log("changes=", changes);

		if (changes.hideButton != undefined) {
			//console.log("config_ind=", this.hideConfig_ind);
			//console.log("hideButton=" + this.hideButton + "\noldhideButton=", this.oldhideButton);
			if (this.hideButton != undefined && this.hideButton != this.oldhideButton) { //Si hideButton=undefined c'est qu'il sagit de l'initialisation de la page
				if (this.hideButton == true) {
					this.graphConfigCumul.data.datasets[this.hideConfig_ind].hidden = true;
					//console.log("datasets[configind]=", this.graphConfigCumul.data.datasets[this.hideConfig_ind]);
				}
				else {
					this.graphConfigCumul.data.datasets[this.hideConfig_ind].hidden = false;
					//console.log("datasets[configind]=", this.graphConfigCumul.data.datasets[this.hideConfig_ind]);
				}
				this.graphConfigCumul.update();
			}

			else { //S'il sagit de l'initialisation de la page

				this.getGraphConfigCumul_init();
				if (this.client_configs != undefined) {
					this.getClientConfig(this.client_configs);
				}

			}

		}
		else {
			this.getGraphConfigCumul_init();
			if (this.client_configs != undefined) {
				this.getClientConfig(this.client_configs);
			}
		}



		/*console.log("piechart_configs=", this.pieChart_configs);
		console.log("client confgis=", this.client_configs);
		console.log("graph configs=", this.graph_configs);*/
		this.configdataService.pieChart_configsSubject.next(this.pieChart_configs);
		this.configdataService.Config_PieChartSubject.next(this.Config_PieChart);
	}



	getClientConfig(configs: ConfigTri[]) { //Fait passer les configs nouvellement entrées une par une dans getGraphConfigCumul()

		for (let i = 0; i < configs.length; i++) {
			this.getConfigColor(configs[i]);
			this.graph_configs.push(configs[i]);
			this.getGraphConfigCumul(configs[i]);
		}

	}

	getConfigColor(config: ConfigTri) {
		for (let j = 0; j < this.configArray.length; j++) { //On attribut la color a chaque config testée ici car on a besoin de configArray calcule dans tab-config-by-day et de client_configs calculé dans config-form
			if (this.configArray[j].tri16A == config.tri16A
				&& this.configArray[j].tri32A == config.tri32A
				&& this.configArray[j].tri63A == config.tri63A) {
				config.color = this.configArray[j].color;
			}
		}
	}



	getGraphConfigCumul(config: ConfigTri) {
		//console.log("Début getGraphConfigCumul");
		let date = Date.now();

		let config_pourcentage: number = 0; //pourcentage de commandes que la config choisie couvre en + sur la graph
		let config_cumuls: number[] = []; //nb de fois que la config choisie apparaît, par jour 
		let config_cumul: number = 0; //nb de fois que la config choisie par l'utilisateur apparaît, sur la période
		let command_total: number = 0; //nb de commandes restantes sur la période

		let index: number = 0;

		for (let i = 0; i < this.tabConfigTri.length; i++) { //Pour chaque jour i

			config_cumuls.push(0);

			for (let j = 0; j < this.tabConfigTri[i].config.length; j++) { //Pour chaque config j utilisée à la date i

				if (this.tabConfigTri[i].config[j].tri16A <= config.tri16A
					&& this.tabConfigTri[i].config[j].tri32A <= config.tri32A
					&& this.tabConfigTri[i].config[j].tri63A <= config.tri63A) { //Si la config choisie peut être utilisée

					let couverture: boolean = true;

					for (let k = 0; k < this.graph_configs.length - 1; k++) { //"length-1" pour ne pas entrer dans cette boucle for pour la 1ère config testée

						if (this.tabConfigTri[i].config[j].tri16A <= this.graph_configs[k].tri16A
							&& this.tabConfigTri[i].config[j].tri32A <= this.graph_configs[k].tri32A
							&& this.tabConfigTri[i].config[j].tri63A <= this.graph_configs[k].tri63A) {//si la config j est déjà comptée par la config k sur le graph

							couverture = false; //la config j n'apporte pas de couverture en + au graph
						}

					}

					if (couverture == true) { //Si la config j couvre davantage le graph sans être déjà comptée sur le graph

						config_cumuls[i] += this.tabConfigTri[i].config[j].nbOfCommandes; //Nb de fois que la config j apparaît, à la date i  
						this.command_cumul[i] = this.command_cumul[i] - this.tabConfigTri[i].config[j].nbOfCommandes; //Nb de commandes restantes à la date i


						//Boucle for pour le calcul du pieChart :
						for (let m = 0; m < this.pieChart_configs.length; m++) {

							if (this.pieChart_configs[m].tri16A == config.tri16A
								&& this.pieChart_configs[m].tri32A == config.tri32A
								&& this.pieChart_configs[m].tri63A == config.tri63A) {//Si la config de pieChart_configs == config choisie

								index = m;
								if (this.tabConfigTri[i].config[j].tri16A != config.tri16A
									|| this.tabConfigTri[i].config[j].tri32A != config.tri32A
									|| this.tabConfigTri[i].config[j].tri63A != config.tri63A) {//Si la config j != config choisie (Pour pas qu'il n'y ai 2 fois le NbofCommandes de la config choisie)

									this.pieChart_configs[m].nbOfCommandes += this.tabConfigTri[i].config[j].nbOfCommandes; //On ajoute au NbofCommandes de la config choisie, le NbOfCommandes de la config j
								}
							}
							if ((this.pieChart_configs[m].tri16A == this.tabConfigTri[i].config[j].tri16A //Si la config m == config j qui couvre du graph
								&& this.pieChart_configs[m].tri32A == this.tabConfigTri[i].config[j].tri32A
								&& this.pieChart_configs[m].tri63A == this.tabConfigTri[i].config[j].tri63A)
								&& (this.tabConfigTri[i].config[j].tri16A != config.tri16A //&& config m != config choisie (pour ne pas la supprimer)
									|| this.tabConfigTri[i].config[j].tri32A != config.tri32A
									|| this.tabConfigTri[i].config[j].tri63A != config.tri63A)) {

								this.pieChart_configs.splice(m, 1); //On supprime dans pieChart_configs la config couverte par la config choisie 
							}
						}

					}

				}
			}
			config_cumul += config_cumuls[i];
			command_total += this.command[i];
		}
		config_pourcentage = +(config_cumul * 100 / command_total).toPrecision(3);


		if (config_pourcentage == 0) {
			this.getSpliceConfig(config);
			console.log("piechart_configs=", this.pieChart_configs);
			alert("La config" + config.tri16A + " 16A | " + config.tri32A + " 32A | " + config.tri63A + " 63A " + "ne couvre pas le graphique davantage.")
		}
		else {

			//Tri pieChart_configs : Les config testées en début d'array, dans l'ordre de test
			var x = index; var pos = this.graph_configs.length - 1; var temp = this.pieChart_configs[x];

			for (let i = x; i >= pos; i--) {
				this.pieChart_configs[i] = this.pieChart_configs[i - 1];
			}
			this.pieChart_configs[pos] = temp;
			this.pieChart_configs[pos].pourcentage = config_pourcentage;

			console.log("piechart_configs=", this.pieChart_configs);

			this.graphConfigCumul.data.datasets.push({});
			this.graphConfigCumul.data.datasets[this.graphConfigCumul.data.datasets.length - 2] = {
				data: config_cumuls,
				label: '\ud83d\udc41' + config.tri16A + 'x16A | '
					+ config.tri32A + 'x32A | '
					+ config.tri63A + 'x63A : '
					+ config_pourcentage + '%',
				backgroundColor: config.color,
				hidden: false
			};
			this.graphConfigCumul.data.datasets[this.graphConfigCumul.data.datasets.length - 1] = { //Nb total de commandes doit être le dernier élément de datasets pour l'affichage
				data: this.command_cumul,
				label: '\ud83d\udc41' + 'Total number of commands',
				borderColor: 'blue'
			}
			this.graphConfigCumul.options.title.text = "Couverture des dates pour lesquelles les configurations choisies peuvent être utilisées";

			this.graphConfigCumul.update();
		}

		this.configdataService.graphConfigCumulSubject.next(this.graphConfigCumul);

		console.log("Fin getGraphConfigCumul, Temps de calcul = ", Date.now() - date);
	}//getGraphConfigCumul()


	getGraphConfigCumul_init() {

		this.pieChart_configs = []; //configs présentes sur la camembert
		this.graph_configs = []; //configs présentes sur le graph

		this.command_cumul = []; //Nb de commandes restantes à la date i

		this.pieChart_configs = this.configdataService.getArrayCopy(this.configArray);


		if (this.graphConfigCumul != undefined) {
			this.graphConfigCumul.destroy();//On supprime le graph et le camembert
		}
		if (this.Config_PieChart != undefined) {
			this.Config_PieChart.destroy();
		}


		for (let i = 0; i < this.tabConfigTri.length; i++) { //Pour chaque jour i
			this.command_cumul.push(0);
			this.command_cumul[i] = this.command[i]; //this.command[i]:Nb de commandes à la date i    
		}

		//On crée le graph
		var ctxConfigCumul = document.getElementById('graphConfigCumul') as HTMLCanvasElement;
		var contextConfigCumul = ctxConfigCumul.getContext('2d');
		//ctxConfigCumul.height = 170;

		this.graphConfigCumul = new Chart(contextConfigCumul, {
			type: 'bar',
			data: {
				labels: this.Dates,
				datasets: [{
					data: this.command_cumul,
					label: 'Nombre total de commandes',
					borderColor: 'blue'
				}]
			},
			options: {
				legend: {
					display: true,
					position: 'top'
				},
				scales: {
					xAxes: [{
						stacked: true
					}],
					yAxes: [{
						stacked: true
					}]
				}
			}
		});


	}

	getSpliceConfig(config: ConfigTri) {
		for (let i = 0; i < this.client_configs.length; i++) {
			if (config.tri16A == this.client_configs[i].tri16A
				&& config.tri32A == this.client_configs[i].tri32A
				&& config.tri63A == this.client_configs[i].tri63A) {
				this.client_configs.splice(i, 1);
				this.configdataService.spliceConfigSubject.next(config);
			}
			if (this.graph_configs[i] != undefined
				&& config.tri16A == this.graph_configs[i].tri16A
				&& config.tri32A == this.graph_configs[i].tri32A
				&& config.tri63A == this.graph_configs[i].tri63A) {
				this.graph_configs.splice(i, 1);
			}
		}

	}

}//report
