import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ConfigTri } from '../../models/ConfigTri.model';
import { cumulConfigTri } from '../../models/cumulConfigTri.model';
import { DataService } from '../../services/data.service'; //DataService du projet
import { ConfigDataService } from '../services/config-data.service'; //DataService de analyse-config

@Component({
	selector: 'app-tab-config-by-day-calcul',
	templateUrl: './tab-config-by-day-calcul.component.html',
	styleUrls: ['./tab-config-by-day-calcul.component.scss']
})
export class TabConfigByDayCalculComponent implements OnChanges {

	records_sorted: any[];

	nbOfDays_file: number; //Nb de jours sur la période du fichier (période par défaut)

	@Input() datesChange: number; //Utilisé pour recalculer les vecteurs lorsque l'utilisateur change les dates de la période

	configArray: ConfigTri[] = [];//Vecteur de configs restreintes aux prises tri : name,tri16A,tri32A,tri63A,nbOfCommands ; sur la période choisie
	configArray_init: ConfigTri[]; //configArray sur tout le fichier

	@Input() tabConfigTri: cumulConfigTri[] = [];//Vecteur d'objets: date + config tri

	command: number[] = [];//Contient le nombre de commandes par jour, utilisé pour tracer graphConfigCumul

	constructor(private dataService: DataService, private configdataService: ConfigDataService) {
		this.dataService.currentrecords_sorted.subscribe(
			tab_records_sorted => this.records_sorted = tab_records_sorted
		);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.configArray_init != undefined) {
			this.getTabConfigByDay();
		}
		else {
			this.configArray_init = [];
			this.configArray_init = this.getTabConfigByDay();
		}

	}

	getTabConfigByDay() {
		console.log("Début getTabConfigByDay()");
		let time = Date.now();

		this.configArray = []; //Contient les différentes configs (objet ConfigTri) sur la période
		this.command = []; //Contient le nb de commandes total par jour, sur la période

		for (let i = 0; i < this.tabConfigTri.length; i++) {
			this.command.push(0);

			for (let j = 0; j < this.records_sorted.length; j++) {

				if (this.records_sorted[j].debutDate.getFullYear() <= this.tabConfigTri[i].date.getFullYear() && this.tabConfigTri[i].date.getFullYear() <= this.records_sorted[j].finDate.getFullYear() &&
					this.records_sorted[j].debutDate.getMonth() <= this.tabConfigTri[i].date.getMonth() && this.tabConfigTri[i].date.getMonth() <= this.records_sorted[j].finDate.getMonth() &&
					this.records_sorted[j].debutDate.getDate() <= this.tabConfigTri[i].date.getDate() && this.tabConfigTri[i].date.getDate() <= this.records_sorted[j].finDate.getDate()) {
					//si la commande j est effective au jour i
					this.command[i] = this.command[i] + 1; //Nb de commandes au jour i

					let tri16A = +this.records_sorted[j].tri16AInt + +this.records_sorted[j].tri16APerm;
					let tri32A = +this.records_sorted[j].tri32AInt + +this.records_sorted[j].tri32APerm;
					let tri63A = +this.records_sorted[j].tri63AInt + +this.records_sorted[j].tri63APerm;

					//configArray contient les différentes config sur la période : utilisé pour tracer le camembert
					let configcumul = this.getConfigCumul(this.configArray, tri16A, tri32A, tri63A);
					configcumul.nbOfCommandes = configcumul.nbOfCommandes + 1;

					let configcumul1 = this.getConfigCumul(this.tabConfigTri[i].config, tri16A, tri32A, tri63A);
					configcumul1.nbOfCommandes = configcumul1.nbOfCommandes + 1;
				}
			}
		}
		this.configArray.sort(function (a, b) { return a.nbOfCommandes - b.nbOfCommandes }).reverse(); //Trié par nbOfCommandes décroissant
		this.getConfigColor();

		console.log("Fin getTabConfigByDay(), Temps de calcul=", Date.now() - time);

		this.configdataService.tabConfigTriSubject.next(this.tabConfigTri);
		this.configdataService.configArraySubject.next(this.configArray);
		this.configdataService.commandSubject.next(this.command);

		return this.configArray;
	}

	getConfigCumul(Config: any, tri16A: number, tri32A: number, tri63A: number) {

		for (let k = 0; k < Config.length; k++) {
			if (tri16A == Config[k].tri16A
				&& tri32A == Config[k].tri32A
				&& tri63A == Config[k].tri63A) { //si la configuration est déjà enregistrée dans "Config"
				return Config[k];
			}
		}

		let config = new ConfigTri(); //Sinon, on la crée
		config.tri16A = tri16A;
		config.tri32A = tri32A;
		config.tri63A = tri63A;
		config.nbOfCommandes = 0;
		Config.push(config);
		return config;
	}

	getConfigColor() {

		for (let i = 0; i < this.configArray.length; i++) { //on attribue à chaque config de configArray sa color, à partir de configArray_init

			if (this.datesChange == 0) { //on crée les couleurs une seule fois (si l'utilisateur a rentré sa 1ère période)
				var x = Math.floor(Math.random() * 256); //Math.random() renvoie un nombre flottant pseudo-aléatoire compris dans l'intervalle [0, 1[
				var y = Math.floor(Math.random() * 256); //Math.floor(x) renvoie le plus grand entier qui est inférieur ou égal à un nombre x
				var z = Math.floor(Math.random() * 256);
				this.configArray[i].color = "rgb(" + x + "," + y + "," + z + ")";
			}
			for (let j = 0; j < this.configArray_init.length; j++) {
				if (this.configArray[i].tri16A == this.configArray_init[j].tri16A
					&& this.configArray[i].tri32A == this.configArray_init[j].tri32A
					&& this.configArray[i].tri63A == this.configArray_init[j].tri63A) {
					this.configArray[i].color = this.configArray_init[j].color;
				}
			}

		}

		for (let i = 0; i < this.tabConfigTri.length; i++) { //on donne à chaque config de tabConfigTri sa color, à partir de configArray
			for (let j = 0; j < this.tabConfigTri[i].config.length; j++) {
				for (let k = 0; k < this.configArray.length; k++) {
					if (this.tabConfigTri[i].config[j].tri16A == this.configArray[k].tri16A
						&& this.tabConfigTri[i].config[j].tri32A == this.configArray[k].tri32A
						&& this.tabConfigTri[i].config[j].tri63A == this.configArray[k].tri63A) {
						this.tabConfigTri[i].config[j].color = this.configArray[k].color;
					}
				}
			}
		}

	}




}//report

