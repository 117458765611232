import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Commande } from '../models/Commands.model';
import { OverviewData } from '../models/overviewData.model';

//https://nicolasfazio.ch/programmation/angular/angular-creer-service-reactif-observables

@Injectable({
	providedIn: 'root'
})
export class DataService {
	private fileNameSubject = new BehaviorSubject<any>("Needs in electrical boxes");
	public fileName$ = this.fileNameSubject.asObservable();

	private nbOfLinesSubject = new BehaviorSubject<any>(0);
	public nbOfLines$ = this.nbOfLinesSubject.asObservable();

	private records_sortedSubject = new BehaviorSubject<any>(0);
	public currentrecords_sorted = this.records_sortedSubject.asObservable();

	private dateMinSubject = new BehaviorSubject<any>(0);
	public dateMin$ = this.dateMinSubject.asObservable();

	private dateMaxSubject = new BehaviorSubject<any>(0);
	public dateMax$ = this.dateMaxSubject.asObservable();

	private nbOfDaysSubject = new BehaviorSubject<any>(0);
	public nbOfDays$ = this.nbOfDaysSubject.asObservable();

	private overviewDataSubject = new BehaviorSubject<OverviewData>(null);
	public overviewData$ = this.overviewDataSubject.asObservable();

	private spinnerVisibleSubject = new BehaviorSubject<any>(false);
	public spinnerVisible$ = this.spinnerVisibleSubject.asObservable();

	constructor() { }

	resetData() {
		this.fileNameSubject.next('Needs in electrical boxes');
		this.nbOfLinesSubject.next(0);
		this.records_sortedSubject.next([]);
		this.dateMinSubject.next('');
		this.dateMaxSubject.next('');
		this.overviewDataSubject.next(null);
	}

	sendData(name: string, records_sorted: any[], dateMin: number, dateMax: number) {
		this.fileNameSubject.next(name);
		this.nbOfLinesSubject.next(records_sorted.length);
		this.records_sortedSubject.next(records_sorted);
		this.dateMinSubject.next(dateMin);
		this.dateMaxSubject.next(dateMax);
		let diffDate = Math.abs(dateMax - dateMin); //diffDate est en ms
		this.nbOfDaysSubject.next(Math.round((diffDate / (1000 * 60 * 60 * 24))));
	}

	showHeaderSpinner() {
		this.spinnerVisibleSubject.next(true);
	}

	hideHeaderSpinner() {
		this.spinnerVisibleSubject.next(false);
	}

	async computeFile(fileName: string, csvData: string, hasHeader: boolean) {
		let csvRecordArray: String[] = csvData.split(/\r\n|\n/);  //csvRecordArray est un array où chaque élément est une ligne du file .csv (/!\ y compris l'en-tête s'il y en a)
		let i = 0;
		let fl = hasHeader ? 1 : 0;
		let records: any[] = [];

		if (csvRecordArray.length == 0) {
			//TODO: cas fichiers vides
			return;
		}
		let nbCols = csvRecordArray[0].split(';').length;
		let dateMin = null;
		let dateMax = null;
		try {
			for (i = fl; i < csvRecordArray.length; i++) {
				if (csvRecordArray[i].length > 0) {

					let currentRecord = (csvRecordArray[i]).split(';');  //séparateurs de notre fichier csv sont des ";"
					//curruntRecord récupère chaque élément de la ligne i et en fait un vecteur de taille la longueur de la ligne
					if (currentRecord.length == nbCols) {
						let commande: Commande = new Commande();  //on crée une commande de type Commande (cf Commande.model.ts)
						commande.salonName = currentRecord[0].trim();  //trim removes les espaces de par et d'autre d'une string
						commande.hallName = currentRecord[1].trim();
						commande.puissance = Number(currentRecord[2].trim());
						commande.debutDate = this.parseDate(currentRecord[3].trim());
						commande.finDate = this.parseDate(currentRecord[4].trim());
						dateMin = dateMin === null ? commande.debutDate : (dateMin.getTime() < commande.debutDate.getTime() ? dateMin : commande.debutDate);
						dateMax = dateMax === null ? commande.finDate : (dateMax.getTime() > commande.finDate.getTime() ? dateMax : commande.finDate);
						commande.monoInt = currentRecord[5] === undefined ? 0 : Number(currentRecord[5].trim());
						commande.tri16AInt = currentRecord[6] === undefined ? 0 : Number(currentRecord[6].trim());
						commande.tri32AInt = currentRecord[7] === undefined ? 0 : Number(currentRecord[7].trim());
						commande.tri63AInt = currentRecord[8] === undefined ? 0 : Number(currentRecord[8].trim());
						commande.monoPerm = currentRecord[9] === undefined ? 0 : Number(currentRecord[9].trim());
						commande.tri16APerm = currentRecord[10] === undefined ? 0 : Number(currentRecord[10].trim());
						commande.tri32APerm = currentRecord[11] === undefined ? 0 : Number(currentRecord[11].trim());
						commande.tri63APerm = currentRecord[12] === undefined ? 0 : Number(currentRecord[12].trim());
						records.push(commande); //commande est un object, csvArr un array d'object
					}
				}
			}

			let records_sorted = records.slice(); //records_sorted contient records trié dans l'ordre chronologique
			records_sorted.sort(function (a, b) { return Date.parse(a.debutDate) - Date.parse(b.debutDate) });

			this.sendData(fileName, records_sorted, dateMin, dateMax);
		}
		catch (e) {
			throw Error(e + ' (Ligne ' + i + ') \n' + csvRecordArray[i]);
		}
	}

	parseDate(date: string) {
		let re = /(\d{4})-([0-1][0-9])-([0-3][0-9])/;

		let r = date.match(re);
		if (r) {
			let dt = new Date(+r[1], +r[2] - 1, +r[3], 0, 0, 0);
			return dt;
		} else {
			throw new Error(' Date invalide: ' + date);
		}
	}

	async getOverviewData() {
		//pas de données déjà chargées
		if (this.overviewDataSubject.getValue() == null) {
			let ovd = new OverviewData();
			ovd.dates = [];
			ovd.nbOfOrders = [];

			let records = this.records_sortedSubject.getValue();
			let dateDebut = this.dateMinSubject.getValue();
			for (let i = 0; i < this.nbOfDaysSubject.getValue(); i++) {
				let d = new Date(dateDebut);
				d.setDate(d.getDate() + i);
				ovd.dates.push(d);
				ovd.nbOfOrders.push(0);
				let t = d.getTime();
				for (let j = 0; j < records.length; j++) {
					if (records[j].debutDate.getTime() <= t && t <= records[j].finDate.getTime()) {
						//si la commande j est effective au jour i
						ovd.nbOfOrders[i] = ovd.nbOfOrders[i] + 1; //Nb de commandes au jour i
					}
				}
			}
			this.overviewDataSubject.next(ovd);
		}
	}
}