export class Commande {
	public salonName: string;
	public hallName: string;
	public puissance: number;
	public debutDate: any; //date ?
	public finDate: any;
	public monoInt: number;
	public tri16AInt: number;
	public tri32AInt: number;
	public tri63AInt: number;
	public monoPerm: number;
	public tri16APerm: number;
	public tri32APerm: number;
	public tri63APerm: number;
}