import { Component, Input } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
	@Input() newImport: boolean;
	@Input() backButton: boolean;

	constructor(private dataService: DataService) {
	}

	get fileNameFromService() {
		// return data straight from service without binding in component
		return this.dataService.fileName$;
	}

	get nbOfLinesFromService() {
		// return data straight from service without binding in component
		return this.dataService.nbOfLines$;
	}

	get nbOfDaysFromService() {
		// return data straight from service without binding in component
		return this.dataService.nbOfDays$;
	}

	get dateMinFromService() {
		// return data straight from service without binding in component
		return this.dataService.dateMin$;
	}

	get dateMaxFromService() {
		// return data straight from service without binding in component
		return this.dataService.dateMax$;
	}

	get isSpinnerVisibleFromService() {
		return this.dataService.spinnerVisible$;
	}
}
