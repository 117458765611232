import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Chart } from 'node_modules/Chart.js';
import { ConfigTri } from '../../models/ConfigTri.model';

@Component({
	selector: 'app-pie-chart',
	templateUrl: './pie-chart.component.html',
	styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnChanges {

	@Input() configArray: ConfigTri[];
	@Input() client_configs: ConfigTri[];
	@Input() pieChart_configs: ConfigTri[]; //Contient les config présentes sur le camembert
	@Input() Config_PieChart: Chart;
	@Input() boolShowGraphCumul: boolean;


	constructor() { }

	ngOnChanges(changes: SimpleChanges) {

		if (this.boolShowGraphCumul == false) {
			document.getElementById("pieChartContent").style.display = 'block';
		}
		else {
			document.getElementById("pieChartContent").style.display = 'none';
		}

		//console.log("piechart_configs=",this.pieChart_configs);
		//console.log("changes=",changes);
		this.getConfig_PieChart();
	}

	getConfig_PieChart(config?: ConfigTri) {

		let cumul: number[] = [];
		let colors: string[] = [];
		let tooltips: string[] = [];


		if (this.Config_PieChart != undefined && this.Config_PieChart != 0) {
			this.Config_PieChart.destroy(); //On supprime le camembert s'il existe déjà 
		}

		for (let i = 0; i < this.pieChart_configs.length; i++) {

			cumul.push(this.pieChart_configs[i].nbOfCommandes);
			colors.push('whitesmoke');
			tooltips.push(this.pieChart_configs[i].tri16A + 'x16A ' + this.pieChart_configs[i].tri32A + 'x32A ' + this.pieChart_configs[i].tri63A + 'x63A');

			if (i < this.client_configs.length) {
				colors[i] = this.pieChart_configs[i].color;
			}

		}

		if (config != undefined) {
			for (let i = 0; i < this.configArray.length; i++) {  //On regarde chaque config présentes sur la période

				if (this.configArray[i].tri16A <= config.tri16A
					&& this.configArray[i].tri32A <= config.tri32A
					&& this.configArray[i].tri63A <= config.tri63A) { //Si la config choisie couvre la config i

					for (let j = 0; j < this.client_configs.length; j++) { //On complète le paramètre "comment" des config de config_clients lorsqu'elles couvrent d'autres config
						if (this.client_configs[j].tri16A == config.tri16A
							&& this.client_configs[j].tri32A == config.tri32A
							&& this.client_configs[j].tri63A == config.tri63A) {
							this.client_configs[j].comment.push('Config ' + (i + 1) + ' : '  //On prend le nom de chaque config couverte pour l'afficher sur la page (ne sert pas pour le camembert)
								+ this.configArray[i].tri16A + ' Tri16A, '
								+ this.configArray[i].tri32A + ' Tri32A, '
								+ this.configArray[i].tri63A + ' Tri63A.')
						}
					}

				}

			}
		}



		var ctxConfig = document.getElementById('Config_PieChart') as HTMLCanvasElement; //On recrée un nouveau graph avec nos nouveaux tableaux
		var contextConfig = ctxConfig.getContext('2d');

		this.Config_PieChart = new Chart(contextConfig, {

			type: 'pie',
			data: {
				labels: tooltips,
				datasets: [{
					backgroundColor: colors,
					data: cumul,
				}]
			},
			options: {
				rotation: - Math.PI,
				/*title: {
				  display: true,
				  text: 'Nombre de commandes par configuration'
				},*/
				legend: {
					display: false
				}
			}
		});


		this.Config_PieChart.update(); //Pas sur que le update soit vraiment utile ici mais je le met au cas où



	}



}
