import { Injectable } from '@angular/core';

@Injectable()
export class CookieService {

	getCookie(name: string): string {
		const value = "; " + document.cookie;
		const parts = value.split("; " + name + "=");
		if (parts.length == 2) {
			return parts.pop().split(";").shift();
		}
	}

	setCookie(name: string, val: string) {
		const date = new Date();
		const value = val;
		date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000));
		document.cookie = name + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
	}
}