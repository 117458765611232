<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">


<form [formGroup]="configForm">
    <div formArrayName="configs" *ngFor="let config of configForm.get('configs')['controls']; let i = index;">
        <div id="form{{i}}" [formGroupName]="i">

            <div *ngIf="client_configs!=undefined && client_configs[i]!=undefined" class="divButtonRemove">
                <button id="deleteButton{{i}}" type="button" class="buttonRemove"
                    (click)="getDeleteConfig('deleteButton'+i)">
                    <span class="material-icons remove">
                        remove_circle
                    </span>
                </button>
            </div>

            <input type="number" class="form-control socket" form="form-control" formControlName="tri16A" min="0">
            x16A &nbsp;&nbsp;
            <input type="number" class="form-control socket" form="form-control" formControlName="tri32A" min="0">
            x32A &nbsp;&nbsp;
            <input type="number" class="form-control socket" form="form-control" formControlName="tri63A" min="0">
            x63A



            <div *ngIf="client_configs!=undefined && client_configs[i]!=undefined"> 

                <!--Overall coverage : {{covering_percentage}} %
                &nbsp;-->

                <app-overequipment [config]="client_configs[i]" [tabConfigTri]="tabConfigTri" [config_ind]="i"
                    [command]="command" [Dates]="Dates"> </app-overequipment>

                <span class="material-icons pastille" style="color: {{client_configs[i].color}};">
                    stop
                </span> 
                &nbsp;

                <span *ngIf="client_configs[i].hideButton == false">
                    <button class="buttonVisibility" (click)="hideConfig('hide',i)">
                        <span class="material-icons visibility"> visibility </span>
                    </button>
                </span>

                <span *ngIf="client_configs[i].hideButton == true">
                    <button class="buttonVisibility" (click)="hideConfig('show',i)">
                        <span class="material-icons visibility"> visibility_off </span>
                    </button>
                </span>
                
            </div>

            <hr>
        </div>
    </div>
</form>
<br>


<button id="addButton" type="button" class="buttonAdd" (click)="addConfig()">
    <span class="material-icons add">
        add_circle
    </span>
</button>


&nbsp;&nbsp; <button type="button" class="buttonValidate" (click)="getClientConfigs()">
    <span class="material-icons validate">
        done
    </span>
</button>