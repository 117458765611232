import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AnalyseConfigComponent } from './analyse-config/analyse-config.component';
import { ConfigFormComponent } from './analyse-config/config-form/config-form.component';
import { GraphCumulComponent } from './analyse-config/graph-cumul/graph-cumul.component';
import { OverequipmentComponent } from './analyse-config/overequipment/overequipment.component';
import { PeriodFormComponent } from './analyse-config/period-form/period-form.component';
import { PieChartComponent } from './analyse-config/pie-chart/pie-chart.component';
import { TabConfigByDayCalculComponent } from './analyse-config/tab-config-by-day-calcul/tab-config-by-day-calcul.component';
import { AnalyseTrancheComponent } from './analyse-tranche/analyse-tranche.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CsvFileImportComponent } from './csv-file-import/csv-file-import.component';
import { HeaderComponent } from './header/header.component';
import { OverviewPageComponent } from './overview-page/overview-page.component';

import { NgxLoadingModule } from 'ngx-loading';
import { CookieService } from './services/cookie.service';

const appRoutes: Routes = [
	{ path: 'overview', component: OverviewPageComponent },
	{ path: 'analyse-tranche', component: AnalyseTrancheComponent },
	{ path: 'analyse-config', component: AnalyseConfigComponent },
	{ path: 'analyse-config/pie-chart', redirectTo: 'analyse-config' },
	{ path: 'analyse-config/graph-cumul', redirectTo: 'analyse-config' },
	{ path: '', component: CsvFileImportComponent },
]

@NgModule({
	declarations: [
		AppComponent,
		CsvFileImportComponent,
		OverviewPageComponent,
		HeaderComponent,
		AnalyseTrancheComponent,
		AnalyseConfigComponent,
		ConfigFormComponent,
		PeriodFormComponent,
		TabConfigByDayCalculComponent,
		GraphCumulComponent,
		PieChartComponent,
		OverequipmentComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		RouterModule.forRoot(appRoutes),
		FormsModule, ReactiveFormsModule,
		NgxLoadingModule.forRoot({})
	],
	providers: [
		CookieService,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	bootstrap: [AppComponent]
})
export class AppModule { }
