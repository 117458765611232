<app-header [backButton]="true"
			[newImport]="true"></app-header>

<div class="d-flex justify-content-start">
	<div class="col-3">
		<div class="empty-state"
			 id="empty-state">
			<div class="card text-center bg-light">
				<div class="card-body">
					<span class="material-icons empty-state">
						insert_chart
					</span>
					<div class="empty-state sentence">
						You don't have any slice of calibers entered...
					</div>
					<button class="button first"
							(click)="showEmptyState()">
						<span class="material-icons addTranche">
							add_circle
						</span>
					</button>
				</div>
			</div>
		</div>
		<div id="tranches"
			 class="tranches">
			<div class="card text-center bg-light">
				<h5 class="card-header">Analysis by power unit</h5>
				<div class="card-body">
					<form class="form"
						  [formGroup]="valuesForm">
						<label for="valeur">
							Slice
							<input id="valeur"
								   type="number"
								   class="form-control tranche"
								   formControlName="Valeur"
								   min="0">
							<button type="button"
									class="addTranche"
									(click)="getTabTranches();getTabPuissancesTranchesByDay();getGraphPuissances();
                                         changeStock(0);changeStock(1);resetAllTranche()">
								<span class="material-icons addTranche">
									add_circle
								</span>
							</button>
						</label>
					</form>
					<br>
					<div *ngIf="boolShowTranches == false">0 &lsaquo; T1 &lsaquo; &#x221E;</div>
					<br>
					<div class="showTranches">
						<div *ngIf="boolShowTranches == false">
							Try to enter one value of power range !
						</div>
						<div *ngIf="boolShowTranches == true">

							<div>
								<button class="button remove"
										(click)="deleteTranche(0)">
									<span class="material-icons remove">
										remove_circle
									</span>
								</button>
								0 &lsaquo; T1 &le; {{tabTranches[0]}} kW
								<span id="pastille"
									  class="material-icons pastille"
									  style="color: blue;">
									stop
								</span>
								<button class="buttonHide show"
										id="buttonVisible2"
										(click)="hideTranche(2)">
									<span class="material-icons">
										visibility
									</span>
								</button>
								<button class="buttonHide hide"
										id="buttonVisibleOff2"
										(click)="showTranche(2)">
									<span class="material-icons">
										visibility_off
									</span>
								</button>
							</div>
							<div *ngFor="let valeur of tabTranches;let i = index">
								<div *ngIf="i+1 !== tabTranches.length">
									<button class="button remove"
											(click)="deleteTranche(i+1);resetAllTranche()">
										<span class="material-icons remove">
											remove_circle
										</span>
									</button>
									{{tabTranches[i]}} &lsaquo; T{{i+2}} &le; {{tabTranches[i+1]}} kW
									<span class="material-icons pastille"
										  style="color: {{colors[i]}};">
										stop
									</span>
									<button class="buttonHide show"
											id="buttonVisible{{i+3}}"
											(click)="hideTranche(i+3)">
										<span class="material-icons">
											visibility
										</span>
									</button>
									<button class="buttonHide hide"
											id="buttonVisibleOff{{i+3}}"
											(click)="showTranche(i+3)">
										<span class="material-icons">
											visibility_off
										</span>
									</button>
									<br>
								</div>

							</div>
							<div>
								<span class="material-icons pastille"
									  style="color: silver;">
									stop
								</span>
								{{tabTranches[tabTranches.length-1]}} &lsaquo; T{{tabTranches.length+1}} kW
								<button class="buttonHide show"
										id="buttonVisible{{tabTranches.length+2}}"
										(click)="hideTranche(tabTranches.length+2)">
									<span class="material-icons">
										visibility
									</span>
								</button>
								<button class="buttonHide hide"
										id="buttonVisibleOff{{tabTranches.length+2}}"
										(click)="showTranche(tabTranches.length+2)">
									<span class="material-icons">
										visibility_off
									</span>
								</button>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="col-9">
		<div class="graph">
			<div class="card">
				<div class="card-body">

					<form id="formStockMin"
						  class="formStock"
						  [formGroup]="stockMinForm">
						<label>Stock Min:</label> &nbsp;
						<input type="number"
							   class="form-control stock"
							   formControlName="stock"
							   (keypress)="changeStock(0)">
						<button *ngIf="boolStockMin == false"
								class="buttonStock"
								(click)="showLineStock(0);boolStockMin = true">
							<span class="material-icons stockOff">
								toggle_off
							</span>
						</button>
						<button *ngIf="boolStockMin == true"
								class="buttonStock"
								(click)="hideLineStock(0);boolStockMin = false">
							<span class="material-icons stockOn">
								toggle_on
							</span>
						</button>
					</form>

					<form id="formStockMax"
						  class="formStock"
						  [formGroup]="stockMaxForm">
						<label>Stock Max:</label> &nbsp;
						<input type="number"
							   class="form-control stock"
							   formControlName="stock"
							   (keypress)="changeStock(1)">
						<button *ngIf="boolStockMax == false"
								class="buttonStock"
								(click)="showLineStock(1);boolStockMax = true">
							<span class="material-icons stockOff">
								toggle_off
							</span>
						</button>
						<button *ngIf="boolStockMax == true"
								class="buttonStock"
								(click)="hideLineStock(1);boolStockMax = false">
							<span class="material-icons stockOn">
								toggle_on
							</span>
						</button>
					</form>

					<form class="formDates"
						  [formGroup]="datesForm">
						<label>Period:</label> &nbsp;
						<input type="date"
							   class="form-control date"
							   formControlName="date1"> &nbsp;
						<label> - </label> &nbsp;
						<input type="date"
							   class="form-control date"
							   formControlName="date2">
						<button class="button done"
								(click)="changePeriod()">
							<span class="material-icons done">
								done
							</span>
						</button>
					</form>


					<div>
						<canvas id="graphPuissancesTotal"></canvas>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>