<!-- Côté droit de la page : -->
<br>
<br>
<form class="form-group"
	  [formGroup]="datesForm">

	<input type="date"
		   class="form-control"
		   form="form-control"
		   formControlName="date1"
		   value="default_dateDebut">
	<!--value="yyyy-mm-dd" -->
	&nbsp; - &nbsp;
	<input type="date"
		   class="form-control"
		   form="form-control"
		   formControlName="date2"
		   value="default_dateFin"
		   (change)="getDatesChange() ; getNbOfDays(datesForm.value.date1,datesForm.value.date2) ; getDates()">

</form>

<!-- <button (click)="getDatesChange() ; getNbOfDays(datesForm.value.date1,datesForm.value.date2) ; getDates()"> Valider </button> -->