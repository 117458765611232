import { Chart } from 'node_modules/Chart.js';

export class ConfigTri {
	//public name: string;
	public tri16A: number;  //Somme tri16AInt+tri16APerm
	public tri32A: number; //Somme tri32AInt+tri32APerm
	public tri63A: number;  //Somme tri63AInt+tri63APerm
	public nbOfCommandes?: number = 0;
	public color: string;

	public comment?: string[] = []; //Commentaire dont la couverture de la config
	public graph?: Chart;
	public pourcentage_total?: number; //Pourcentage de commandes que couvre la config sur l'ensemble des commandes de la période
	public pourcentage?: number; //Pourcentage de commandes que la config couvre en + sur le graph
	public overequipButton?: string; //id du bouton Suréquipement de la config
	public deleteButton?: string; //id du bouton Suppression de la config
	public hideButton?: boolean; //Boutton pour cacher/afficher une config sur le graphConfigCumul
}