import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Chart } from 'node_modules/Chart.js';
import { DataService } from '../services/data.service';

@Component({
	selector: 'app-overview-page',
	templateUrl: './overview-page.component.html',
	styleUrls: ['./overview-page.component.scss']
})
export class OverviewPageComponent implements OnInit {

	public records_sorted: any[];

	chart: Chart;

	constructor(private router: Router,
		private dataService: DataService) {
	}

	ngOnInit(): void {
		console.log("ngoninit overview ...");
		this.dataService.showHeaderSpinner();

		this.chart = new Chart('chart', {
			type: 'bar',
			options: {
				legend: {
					display: true,
					position: 'top'
				},
				scales: {
					xAxes: [{
						stacked: true,
					}],
					yAxes: [{
						stacked: true,
						display: 'auto'
					}]
				},
				responsive: true,
				maintainAspectRation: false,
				showScale: false
			}
		});

		this.dataService.overviewData$.subscribe(
			ovd => {
				if (ovd != null) {
					let date = [];
					for (let i = 0; i < ovd.dates.length; i++) {  //On parcourt une période aussi longue que le nombre de jours
						date.push(ovd.dates[i].getDate() + '/' + (ovd.dates[i].getMonth() + 1) + '/' + ovd.dates[i].getFullYear()); //Tableau avec le jour et le mois          
					}
					this.chart.data = {
						labels: date,
						datasets: [{
							data: ovd.nbOfOrders,
							label: 'Total number of orders',
							backgroundColor: 'silver'
						}]
					}
					this.chart.update();
				}
			}
		);

		this.dataService.getOverviewData()
			.then(() => {
				this.dataService.hideHeaderSpinner();
			})
			.catch((err) => {
				console.error(err);
				this.dataService.hideHeaderSpinner();
			});
	}

	get nbOfLinesFromService() {
		// return data straight from service without binding in component
		return this.dataService.nbOfLines$;
	}

	get nbOfDaysFromService() {
		// return data straight from service without binding in component
		return this.dataService.nbOfDays$;
	}

	get dateMinFromService() {
		// return data straight from service without binding in component
		return this.dataService.dateMin$;
	}

	get dateMaxFromService() {
		// return data straight from service without binding in component
		return this.dataService.dateMax$;
	}

	getAnalyseTranche() {
		this.dataService.showHeaderSpinner();
		this.router.navigate(['analyse-tranche']);
	}

	getAnalyseConfig() {
		this.dataService.showHeaderSpinner();
		this.router.navigate(['analyse-config']);
	}
}


