<app-header [backButton]="false"
			[newImport]="true"></app-header>
<div class="text">
	<p>{{nbOfLinesFromService | async}} orders from {{dateMinFromService | async | date: 'mediumDate'}} to
		{{dateMaxFromService | async | date: 'mediumDate'}} ({{nbOfDaysFromService | async}} Days)</p>
</div>

<div class="graph">
	<canvas id="chart"
			height="400px"
			width="1000px"></canvas>
</div>

<br>
<div class="buttons">
	<button class="btn btn-primary"
			type="button"
			(click)="getAnalyseTranche()"> Analysis by power unit </button> &nbsp;
	<!--button class="btn btn-primary" type="button" (click)="getAnalyseConfig()"> Analysis by type of sockets </button-->
</div>