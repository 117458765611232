import { AfterViewInit, Component, Input } from '@angular/core';
import { Chart } from 'node_modules/Chart.js';
import { ConfigTri } from 'src/app/models/ConfigTri.model';
import { cumulConfigTri } from 'src/app/models/cumulConfigTri.model';
import { ConfigDataService } from '../services/config-data.service';

@Component({
	selector: 'app-overequipment',
	templateUrl: './overequipment.component.html',
	styleUrls: ['./overequipment.component.scss']
})
export class OverequipmentComponent implements AfterViewInit {

	@Input() config: ConfigTri;
	@Input() tabConfigTri: cumulConfigTri[];
	//@Input() overequipButton: string;
	@Input() command: number[];
	@Input() Dates: string[];
	@Input() config_ind: number;

	overequip_pourcentage: number;
	covering_pourcentage: number;

	//graphConfigCumulOverequipment: Chart[] = [];
	graphOverequipment: Chart;

	showGraph: boolean = false;

	constructor(private configdataService: ConfigDataService) { }

	ngAfterViewInit() {
		this.getGraphConfigOverequipment(this.config);
		this.configdataService.coveringPercentageSubject.next(this.covering_pourcentage);
	}




	getGraphConfigOverequipment(config: ConfigTri) {


		let config_cumuls_equal: number[] = []; //Tableau avec les configs égales à celle rentrées par l'utilisateur
		let config_cumuls_overequipment: number[] = []; //Tableau avec les configs prises en compte mais pas égales (suréquipement)
		let config_cumul: number = 0; //nb de fois que la config choisie par l'utilisateur a été utilisé
		let overequip_cumul: number = 0; //nb de fois que la config choisie est considéré comme du suréquipement
		let command_total: number = 0; //nb total de commandes sur la période
		let command_cumul: number[] = []; //utilisé pour afficher nb de commandes sur la période
		//let pourcentage: number = 0; //pourcentage d'utilisation de la config choisie, sur la période choisie



		for (let i = 0; i < this.tabConfigTri.length; i++) {

			config_cumuls_overequipment.push(0);
			config_cumuls_equal.push(0);
			command_cumul.push(0);

			for (let j = 0; j < this.tabConfigTri[i].config.length; j++) {
				//On regarde si chaque config est bien inférieure ou égale à la config rentrée
				if (this.tabConfigTri[i].config[j].tri16A <= config.tri16A
					&& this.tabConfigTri[i].config[j].tri32A <= config.tri32A
					&& this.tabConfigTri[i].config[j].tri63A <= config.tri63A) {

					//Si au moins une prise de la config rentrée n'est pas nécessaire pour la config j, on considère que c'est du suréquipement
					if (this.tabConfigTri[i].config[j].tri16A < config.tri16A
						|| this.tabConfigTri[i].config[j].tri32A < config.tri32A
						|| this.tabConfigTri[i].config[j].tri63A < config.tri63A) {
						config_cumuls_overequipment[i] += this.tabConfigTri[i].config[j].nbOfCommandes; //Contient le nb de commandes en suréquipement pour la config et sur la période choisies par l'utilisateur     
					}
					//Sinon la config est égale donc c'est bon
					else if (this.tabConfigTri[i].config[j].tri16A == config.tri16A
						&& this.tabConfigTri[i].config[j].tri32A == config.tri32A
						&& this.tabConfigTri[i].config[j].tri63A == config.tri63A) {
						config_cumuls_equal[i] += this.tabConfigTri[i].config[j].nbOfCommandes; //nb de fois que la config choisie exacte apparaît par jour
					}
				}

			}
			config_cumul += config_cumuls_equal[i] + config_cumuls_overequipment[i];
			overequip_cumul += config_cumuls_overequipment[i];
			command_cumul[i] += (this.command[i] - (config_cumuls_equal[i] + config_cumuls_overequipment[i]));//on soustrait le nb de commandes de cette config au jour i car le graph et un graph bar empilé et non supperposé
			command_total += this.command[i];
		}
		this.covering_pourcentage = +(config_cumul * 100 / command_total).toPrecision(3); //Precision à 1 chiffre après la virgule
		this.overequip_pourcentage = +(overequip_cumul * 100 / command_total).toPrecision(3);

		config.pourcentage_total = this.covering_pourcentage;


		this.createGraphConfigOverequipment(config, config_cumuls_equal, config_cumuls_overequipment, command_cumul);

	}

	createGraphConfigOverequipment(config: ConfigTri, config_cumuls_equal: number[], config_cumuls_overequipment: number[], command_cumul: number[]) {

		if (this.graphOverequipment != undefined) {
			this.graphOverequipment.destroy();
		}

		let id = 'overequipment' + this.config_ind.toString();

		var ctxConfigCumul = document.getElementById(id) as HTMLCanvasElement;
		var contextConfigCumul = ctxConfigCumul.getContext('2d');
		this.graphOverequipment = new Chart(contextConfigCumul, {
			type: 'bar',
			data: {
				labels: this.Dates,
				datasets: [{
					data: config_cumuls_equal,
					label: 'Setup chosen :' + config.tri16A + 'x16A | ' + config.tri32A + 'x32A | ' + config.tri63A + 'x63A',
					backgroundColor: config.color,
				}, {
					data: config_cumuls_overequipment,
					label: 'Overequipment *',
					backgroundColor: 'red'
				}, {
					data: command_cumul,
					label: 'Number of commands uncovered by this setup',
					borderColor: 'blue'
				}]
			},
			options: {
				responsive: true,
				maintainAspectRatio: true,
				legend: {
					display: true,
					position: 'top'
				},
				scales: {
					xAxes: [{
						stacked: true,
					}],
					yAxes: [{
						stacked: true
					}]
				}
			}
		});

		//this.graphConfigCumulOverequipment.push(this.graphOverequipment);

		config.graph = this.graphOverequipment;
	}


}//export
