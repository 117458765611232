<app-header [backButton]="true"
			[newImport]="true"></app-header>

<div class="d-flex justify-content-start">
	<div class="col-4">
		<div class="configuration">
			<div class="card text-center bg-light">
				<h5 class="card-header">Analysis by type of sockets</h5>
				<div class="card-body socket">
					<app-config-form [spliceConfig]="spliceConfig"></app-config-form>
					<app-period-form></app-period-form>
				</div>
			</div>
		</div>
	</div>
	<div class="col-8">
		<div class="graph">
			<div class="card text-center bg-light">
				<div class="card-header">
					<ul class="nav nav-tabs card-header-tabs justify-content-center">
						<li class="nav-item">
							<a id="linkPie"
							   class="nav-link"
							   routerLink="pie-chart"
							   (click)="showPie()">
								<span class="material-icons">
									pie_chart
								</span>
							</a>
						</li>
						<li class="nav-item">
							<a id="linkBar"
							   class="nav-link"
							   routerLink="graph-cumul"
							   (click)="showGraphCumul()">
								<span class="material-icons">
									bar_chart
								</span>
							</a>
						</li>
					</ul>
				</div>
				<div class="card-body">

					<app-tab-config-by-day-calcul [datesChange]="datesChange"
												  [tabConfigTri]="tabConfigTri"> </app-tab-config-by-day-calcul>

					<app-graph-cumul [boolShowGraphCumul]="boolShowGraphCumul"
									 [tabConfigTri]="tabConfigTri"
									 [client_configs]="client_configs"
									 [command]="command"
									 [Dates]="Dates"
									 [configArray]="configArray"
									 [hideButton]="hideButton"> </app-graph-cumul>

					<app-pie-chart [boolShowGraphCumul]="boolShowGraphCumul"
								   [configArray]="configArray"
								   [client_configs]="client_configs"
								   [pieChart_configs]="pieChart_configs"
								   [Config_PieChart]="Config_PieChart"> </app-pie-chart>

				</div>
			</div>
		</div>
	</div>



</div>