<div class="overequipment">

	<button id="overequipButton{{config_ind}}"
			type="button"
			class="btn btn-primary show"
			data-toggle="modal"
			attr.data-target="#overequipmentModal{{config_ind}}">
		Show
	</button>

	<!-- Overall coverage : {{covering_pourcentage}} % -->

	Overequipment : {{overequip_pourcentage}} %

</div>


<!-- Modal -->

<div class="modal fade"
	 id="overequipmentModal{{config_ind}}"
	 tabindex="-1"
	 role="dialog"
	 aria-labelledby="supportModalLabel"
	 aria-hidden="true">
	<div class="modal-dialog modal-lg"
		 role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title"
					id="overequipmentModalLabel">Overequipment</h5>
				<button type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div>
					<canvas id="overequipment{{config_ind}}">{{graphOverequipment}}</canvas>
				</div>
				<div class="asterix">* A config over-equip another when at least one socket is not necessary</div>
			</div>
		</div>
	</div>
</div>