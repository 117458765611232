import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Chart } from 'node_modules/Chart.js';
import { cumulTranches } from '../models/cumulTranches.model';
import { DataService } from '../services/data.service';

@Component({
	selector: 'app-analyse-tranche',
	templateUrl: './analyse-tranche.component.html',
	styleUrls: ['./analyse-tranche.component.scss']
})
export class AnalyseTrancheComponent implements OnInit {

	datesForm: FormGroup; //Formulaire pour les dates
	valuesForm: FormGroup; //Formulaire pour les tranches de calibre
	stockMaxForm: FormGroup; //Formulaire pour la ligne de stock maximum
	stockMinForm: FormGroup; //Formulaire pour la ligne de stock minimum

	tabTranches: any[] = [];
	boolShowTranches: boolean = false;
	nbOfDays: number;
	records_sorted: any[];
	tabPuissancesTranchesByDay = [];
	boolPeriod: boolean = false;
	graphPuissancesTotal: Chart;
	cumul: number[];
	colors = ['turquoise', '#0CD31E', 'orange', 'red', 'pink', 'brown', 'yellow', 'blueviolet', 'purple', 'greenyellow'];
	boolHideButton: boolean = false;
	puissance: Array<any> = []; //Tableau qui contiendra des tableaux qui auront les nombres de commandes pour une tranche [[Tranche1],[Tranche2],...]
	boolStockMax: boolean = false;
	boolStockMin: boolean = false;
	lineStockMaxTab: any[];
	lineStockMinTab: any[];
	dateMin: Date;
	dateMax: Date;

	constructor(private formBuilder: FormBuilder,
		private dataService: DataService) {
		this.dataService.currentrecords_sorted.subscribe(
			tab_records_sorted => this.records_sorted = tab_records_sorted
		);
		this.dataService.nbOfDays$.subscribe(
			nbOfDays => this.nbOfDays = nbOfDays
		);
		this.dataService.overviewData$.subscribe(
			ovd => { if (ovd !== null) this.cumul = ovd.nbOfOrders.slice() }
		);
		this.dataService.dateMin$.subscribe(
			d => this.dateMin = new Date(d)
		);
		this.dataService.dateMax$.subscribe(
			d => this.dateMax = new Date(d)
		);
	}

	ngOnInit(): void {
		this.dataService.showHeaderSpinner();
		this.initForm();
		setTimeout(() => {
			this.initData()
				.then(() => {
					this.dataService.hideHeaderSpinner();
				})
				.catch((err) => {
					this.dataService.hideHeaderSpinner();
				});
		}
			, 0);
	}

	async initData() {
		this.getTabPuissancesTranchesByDay();
		this.getGraphPuissancesInit();
	}

	initForm() {

		let dd = (this.dateMin.getFullYear() + '-' + (this.dateMin.getMonth() + 1).toString().padStart(2, '0') + '-' + this.dateMin.getDate().toString().padStart(2, '0'));
		let df = (this.dateMax.getFullYear() + '-' + (this.dateMax.getMonth() + 1).toString().padStart(2, '0') + '-' + this.dateMax.getDate().toString().padStart(2, '0'));

		this.datesForm = this.formBuilder.group({
			date1: [dd, [Validators.required]],
			date2: [df, [Validators.required]]
		});
		this.valuesForm = this.formBuilder.group({
			Valeur: ['', [Validators.required]]
		});
		this.stockMaxForm = this.formBuilder.group({
			stock: ['', [Validators.required]]
		});
		this.stockMinForm = this.formBuilder.group({
			stock: ['', [Validators.required]]
		});
	}

	showEmptyState() {
		document.getElementById("empty-state").style.display = "none";
		document.getElementById("tranches").style.display = "block";
	}

	getTabTranches() {  //Permet d'avoir le tableau avec la valeur supérieure de chaque tranche
		this.boolShowTranches = true; //Permet d'afficher les tranches

		/*if (this.valuesForm.value.Valeur <= 0) {  //Si la valeur entré est négative
		  alert("Negative value isn't accepted for power range. Please enter a positive value !") //Message d'erreur
		}*/
		if (this.tabTranches.includes(this.valuesForm.value.Valeur)) { //Si la valeur de tranche entré est déjà dans le tableau
			alert('This value has already been entered');  //Message d'erreur
			this.tabTranches = this.tabTranches;
		}
		else {  //Sinon on l'ajoute au tableau puis on trie le tableau dans l'ordre croissant
			this.tabTranches.push(this.valuesForm.value.Valeur);
			this.tabTranches.sort(function (a, b) {
				return a - b
			});
		}
	}

	deleteTranche(i: number) {  //Permet de supprimer une tranche
		this.dataService.showHeaderSpinner();
		if (this.tabTranches.length == 1) {  //On distingue le cas où il ne reste plus qu'une tranche
			this.tabTranches.splice(0, 1);
			this.graphPuissancesTotal.destroy();
			this.getTabPuissancesTranchesByDay();
			this.getGraphPuissancesInit();
			this.boolShowTranches = false;
		}
		else {
			this.tabTranches.splice(i, 1);
			this.getTabPuissancesTranchesByDay();
			this.getGraphPuissances();
		}
		this.dataService.hideHeaderSpinner();
	}

	changePeriod() {  //Permet de changer le graph en fonction de la période rentrée
		this.boolPeriod = true;
		if (this.datesForm.value.date1 >= this.datesForm.value.date2) {
			alert('You entered a wrong period. The starting date must be lower than the closing date !')
		}
		else if (Date.parse(this.datesForm.value.date1) < Date.parse(this.records_sorted[0].debutDate)) {
			alert('The starting date is not included in the file. Please enter a date greater than ' + this.records_sorted[0].debutDate + '!');
		}
		else {
			if (this.tabTranches.length == 0) { //On distingue le cas où il n'y pas de tranches entrées
				this.getTabPuissancesTranchesByDay();
				this.graphPuissancesTotal.destroy();
				this.getGraphPuissancesInit();
			}
			else {
				this.getTabPuissancesTranchesByDay();
				this.getGraphPuissances();
			}
		}
	}

	showLineStock(index: number) {

		if (this.stockMaxForm.value.stock < this.stockMinForm.value.stock && this.stockMaxForm.value.stock != 0) {
			alert('You must enter a maximum stock value upper than the minimum stock value.');
			this.changeStock(0);
			this.changeStock(1);
		} else {
			for (let i = 0; i < this.nbOfDays; i++) {
				this.lineStockMaxTab[i] = this.stockMaxForm.value.stock - this.stockMinForm.value.stock;
				this.lineStockMinTab[i] = this.stockMinForm.value.stock;
			}
		}

		if (index == 1) {
			if (this.boolStockMin == false) {
				for (let j = 0; j < this.nbOfDays; j++) {
					this.lineStockMaxTab[j] = this.stockMaxForm.value.stock;
				}
			}
		}

		this.graphPuissancesTotal.data.datasets[index].hidden = false;
		this.graphPuissancesTotal.update();

	}

	hideLineStock(index: number) {
		if (index == 0) {
			for (let i = 0; i < this.nbOfDays; i++) {
				this.lineStockMaxTab[i] = this.stockMaxForm.value.stock;
			}
		}
		this.graphPuissancesTotal.data.datasets[index].hidden = true;
		this.graphPuissancesTotal.update();
	}

	changeStock(index: number) {
		if (index == 0) {
			this.boolStockMin = false;
		}
		else if (index == 1) {
			this.boolStockMax = false
		}
		this.hideLineStock(index);
	}

	getTabPuissancesTranchesByDay() { //Cette fonction permet d'avoir un tableau d'objet avec la date du jour, un tableau avec le nombre de commandes pour chaque tranche 
		this.tabPuissancesTranchesByDay = [];
		let date = new Date(this.records_sorted[0].debutDate);

		if (this.boolPeriod == true) {
			date = new Date(this.datesForm.value.date1);
			let dateDebut = new Date(this.datesForm.value.date1);  //On transforme les dates entrées au format date
			let dateFin = new Date(this.datesForm.value.date2);
			let diffDate = Date.parse(dateFin.toString()) - Date.parse(dateDebut.toString()); //On calcule la différence entre les deux dates pour avoir le nombre de jours sur lequel on doit faire nos calculs
			this.nbOfDays = (diffDate / (1000 * 60 * 60 * 24)); //On transforme les millièmes de secondes en jours
		}

		for (let i = 0; i < this.nbOfDays; i++) { //Cette partie permet d'avoir un tableau contenant des objets 
			//Chaque objet contient un jour et le tableau a tout les jours depuis la date de début jusqu'à la date de fin
			let newdate = new Date(date.valueOf());
			newdate.setDate(date.getDate() + i); //On rajoute un jour a chaque itération
			let CumulTranches = new cumulTranches; //On initialise un objet
			CumulTranches.nbOfCommandes = [];
			CumulTranches.date = newdate;  //On lui donne la date du jour pour cette itération
			CumulTranches.config_cumul = 0;
			this.tabPuissancesTranchesByDay.push(CumulTranches); //On rentre l'objet dans le tableau final
		}

		for (let j = 0; j < this.tabPuissancesTranchesByDay.length; j++) {  //On parcourt la tableau qui contient chaque jour de la période

			if (this.tabTranches.length == 0) {
				this.tabPuissancesTranchesByDay[j].nbOfCommandes.push(0)
			} else {
				for (let n = 0; n <= this.tabTranches.length; n++) { //Ici on initialise le tableau des nb de commandes pour qu'il ait autant de 0 qu'il y a de tranches de puissances
					this.tabPuissancesTranchesByDay[j].nbOfCommandes.push(0);
				}
			}

			for (let k = 0; k < this.records_sorted.length; k++) { //Pour chaque jour, on parcourt la table de données 
				if (this.records_sorted[k].debutDate.getTime() <= this.tabPuissancesTranchesByDay[j].date.getTime()
					&& this.tabPuissancesTranchesByDay[j].date.getTime() <= this.records_sorted[k].finDate.getTime()) {
					//Si la date du jour est entre la date de début et la date de fin de tel ligne dans notre base de données

					if (this.tabTranches.length == 0) {
						this.tabPuissancesTranchesByDay[j].nbOfCommandes[0] += 1;
					} else {

						//Le tableau Valeurs ne commence pas à 0 mais à la première valeur de borne rentré par l'utilisateur, ce pourquoi on a besoin de faire un cas spécial pour la tranche 0-Valeur1
						if (0 < this.records_sorted[k].puissance && this.records_sorted[k].puissance <= this.tabTranches[0]) { //On regarde si la puissance est entre 0 et la première valeur de notre tableau Valeurs qui possède chaque borne des tranches voulues
							this.tabPuissancesTranchesByDay[j].nbOfCommandes[0] += 1;
						}
						//Il y a également un cas spécial pour la dernière tranche puisque il faut voir toutes les commandes au dessus de cette tranche
						else if (this.tabTranches[this.tabTranches.length - 1] < this.records_sorted[k].puissance) { //Le Valeurs.length-1 permet de se placer au dernier rang du tableau Valeurs
							this.tabPuissancesTranchesByDay[j].nbOfCommandes[this.tabTranches.length] += 1;
						}
						for (let m = 0; m < this.tabTranches.length - 1; m++) {  //On crée un boucle qui parcourt la tableau des valeurs jusqu'à l'avant-dernier rang
							if (this.tabTranches[m] < this.records_sorted[k].puissance && this.records_sorted[k].puissance <= this.tabTranches[m + 1]) {
								this.tabPuissancesTranchesByDay[j].nbOfCommandes[m + 1] += 1;
							}
						}
					}
				}
			}
		}
	}

	getGraphPuissancesInit() {
		let date = [];
		this.lineStockMaxTab = [];
		this.lineStockMinTab = [];

		if (this.boolPeriod == true) {
			this.cumul.splice(0);
		}

		for (let i = 0; i < this.nbOfDays; i++) {  //On parcourt une période aussi longue que le nombre de jours
			date.push(this.tabPuissancesTranchesByDay[i].date.getDate() + '/' + (this.tabPuissancesTranchesByDay[i].date.getMonth() + 1) + '/' + this.tabPuissancesTranchesByDay[i].date.getFullYear()); //Tableau avec le jour et le mois 
			this.lineStockMaxTab.push(0);
			this.lineStockMinTab.push(0);
			if (this.boolPeriod == true) {
				this.cumul.push(this.tabPuissancesTranchesByDay[i].nbOfCommandes[0]);
			}
		}

		var ctxConfigCumul = document.getElementById('graphPuissancesTotal') as HTMLCanvasElement;
		var contextConfigCumul = ctxConfigCumul.getContext('2d');
		this.graphPuissancesTotal = new Chart(contextConfigCumul, {
			type: 'bar',
			data: {
				labels: date,
				datasets: [{
					label: 'Stock Min',
					data: this.lineStockMinTab,
					type: 'line',
					borderColor: 'red',
					fill: false,
					pointStyle: 'line',
					borderDash: [10, 10],
					hidden: true
				}, {
					label: 'Stock Max',
					data: this.lineStockMaxTab,
					type: 'line',
					borderColor: 'green',
					fill: false,
					pointStyle: 'line',
					borderDash: [10, 10],
					hidden: true
				}, {
					data: this.cumul,
					label: 'Number of commands',
					backgroundColor: 'silver'
				}]
			},
			options: {
				legend: {
					display: false,
					position: 'top'
				},
				scales: {
					xAxes: [{
						stacked: true
					}],
					yAxes: [{
						stacked: true
					}]
				},
			}
		});

		if (this.boolStockMax == true) {
			this.showLineStock(0);
		}
		if (this.boolStockMin == true) {
			this.showLineStock(1);
		}
	}

	getGraphPuissances() {

		this.graphPuissancesTotal.destroy();
		this.lineStockMaxTab = [];
		this.lineStockMinTab = [];
		let date = []; //Tableau pour avoir chaque date jour par jour
		this.puissance = [];


		for (let m = 0; m <= this.tabTranches.length; m++) { //On initialise le tableau puissance en lui insérant autant de tableaux vides qu'il y a de tranches
			this.puissance.push([]);
		}

		for (let i = 0; i < this.nbOfDays; i++) {  //On parcourt une période aussi longue que le nombre de jours
			date.push(this.tabPuissancesTranchesByDay[i].date.getDate() + '/' + (this.tabPuissancesTranchesByDay[i].date.getMonth() + 1) + '/' + this.tabPuissancesTranchesByDay[i].date.getFullYear()); //Tableau avec le jour et le mois
			this.lineStockMaxTab.push(0);
			this.lineStockMinTab.push(0);
			for (let j = 0; j <= this.tabTranches.length; j++) {  //Pour chaque jour on parcourt la tableau des valeurs de tranches
				this.puissance[j].push(this.tabPuissancesTranchesByDay[i].nbOfCommandes[j]);
			}
		}

		//Graph des puissances en fonction de tranches (bar)
		var ctxPuissancesTotal = document.getElementById('graphPuissancesTotal') as HTMLCanvasElement;
		var contextPuissancesTotal = ctxPuissancesTotal.getContext('2d');
		this.graphPuissancesTotal = new Chart(contextPuissancesTotal, {
			type: 'bar',
			data: {
				labels: date,
				datasets: [{
					label: 'Stock Min',
					data: this.lineStockMinTab,
					type: 'line',
					borderColor: 'red',
					fill: false,
					pointStyle: 'line',
					borderDash: [10, 10],
					hidden: true
				}, {
					label: 'Stock Max',
					data: this.lineStockMaxTab,
					type: 'line',
					borderColor: 'green',
					fill: false,
					pointStyle: 'line',
					borderDash: [10, 10],
					hidden: true
				}, {
					label: '0-' + this.tabTranches[0] + ' kW',
					data: this.puissance[0],
					backgroundColor: 'blue',
					hidden: false
				}]
			},
			options: {
				legend: {
					display: false,
					position: 'top'
				},
				scales: {
					xAxes: [{
						stacked: true
					}],
					yAxes: [{
						stacked: true,
					}],
				},
				onClick: this.onMoveLine.bind(this),
				/*tooltips: {
				  callbacks: {
					label: function(tooltipItem, lineStockMaxTab) {
					  var label = 'Différence de stock :';
					  return label;
					}
				  }
				}*/
			}
		});

		for (let n = 0; n < this.tabTranches.length - 1; n++) {
			this.graphPuissancesTotal.data.datasets.push({  //Permet de rajouter des data à datasets
				label: (this.tabTranches[n] + 1) + '-' + this.tabTranches[n + 1] + ' kW',
				data: this.puissance[n + 1],
				backgroundColor: this.colors[n],
				hidden: false
			});
		}

		this.graphPuissancesTotal.data.datasets.push({
			label: 'Supérieur à ' + this.tabTranches[this.tabTranches.length - 1] + ' kW',
			data: this.puissance[this.tabTranches.length],
			hidden: false
		});
		this.graphPuissancesTotal.update(); //Important de mettre un update pour que le push marche dans le graph !!!


		if (this.boolStockMax == true) {
			this.showLineStock(0);
		}
		if (this.boolStockMin == true) {
			this.showLineStock(1);
		}
	}

	onMoveLine(e) {
		/*let activePoints = this.graphPuissancesTotal.getElementAtEvent(e);
		console.log(activePoints);
		let index = activePoints[0]._index;
		let datasetIndex = activePoints[0]._datasetIndex;
		let point = activePoints[0]._chart.config.data.datasets[datasetIndex].data[index];
		this.lineStock = point;
		this.getGraphPuissances();*/

		/*var Y = e.pageY;
		console.log("Y=",Y);
		this.lineStock = Y;
		this.getGraphPuissances();*/
	}

	hideTranche(i: number) { //Fonction pour cacher une tranche sur le graph

		for (let k = 2; k <= this.tabTranches.length + 2; k++) {
			if (i == k) {
				this.graphPuissancesTotal.data.datasets[i].hidden = true;
				let id = 'buttonVisible' + k;
				let idString = id.toString();
				let id2 = 'buttonVisibleOff' + k;
				let idString2 = id2.toString();
				document.getElementById(idString).style.display = "none";
				document.getElementById(idString2).style.display = "inline-block"
			}
		}
		this.graphPuissancesTotal.update();
	}

	showTranche(i: number) { //Fonction pour afficher un tranche cachée sur le graph
		if (i == 2) {
			this.graphPuissancesTotal.data.datasets[i].hidden = false;
			document.getElementById("buttonVisible2").style.display = "inline-block";
			document.getElementById("buttonVisibleOff2").style.display = "none";
		} else if (i > 2 && i <= this.tabTranches.length + 2) {
			this.graphPuissancesTotal.data.datasets[i].hidden = false;
			let id = 'buttonVisible' + i;
			let idString = id.toString();
			let id2 = 'buttonVisibleOff' + i;
			let idString2 = id2.toString();
			document.getElementById(idString).style.display = "inline-block";
			document.getElementById(idString2).style.display = "none";
		}
		this.graphPuissancesTotal.update();
	}

	resetAllTranche() {
		for (let k = 2; k <= this.tabTranches.length + 2; k++) {
			let id1 = ('buttonVisible' + k).toString();
			let id2 = ('buttonVisibleOff' + k).toString();
			if (document.getElementById(id1)) {
				document.getElementById(id1).style.display = "inline-block";
			}
			if (document.getElementById(id2)) {
				document.getElementById(id2).style.display = "none";
			}
		}
	}
}
