<link href="https://fonts.googleapis.com/icon?family=Material+Icons"
	  rel="stylesheet">
<nav *ngIf="backButton == false"
	 class="navbar navbar-dark navbar-expand-sm bg-primary">
	<a class="navbar-brand">
		{{ fileNameFromService | async }}
	</a>
	<ul class="navbar-nav ml-auto">
		<li *ngIf="newImport == true"
			class="nav-item">
			<a class="nav-link"
			   routerLink="">New import</a>
		</li>
		<li class="nav-item">
			<div *ngIf="(isSpinnerVisibleFromService | async) == true"
				 class="spinner-border float-right text-light"
				 role="status">
				<span class="sr-only">Loading...</span>
			</div>
		</li>
	</ul>
</nav>

<nav *ngIf="backButton == true"
	 class="navbar navbar-dark navbar-expand-sm bg-primary">
	<a class="navbar-brand"
	   routerLink="/overview">
		<span class="material-icons">
			arrow_back_ios
		</span>
	</a>

	<span class="navbar-text"> {{fileNameFromService | async}} -- {{nbOfLinesFromService | async}} orders -- From
		{{dateMinFromService | async | date: 'mediumDate'}} to
		{{dateMaxFromService | async | date: 'mediumDate'}} ({{nbOfDaysFromService | async}} Days)</span>

	<ul *ngIf="newImport == true"
		class="navbar-nav ml-auto">
		<li class="nav-item active">
			<a class="nav-link"
			   routerLink="">New import</a>
		</li>
		<li *ngIf="(isSpinnerVisibleFromService | async) == true">
			<div class="spinner-border float-right text-light"
				 role="status">
				<span class="sr-only">Loading...</span>
			</div>
		</li>
	</ul>
</nav>