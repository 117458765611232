<app-header [backButton]="false"
			[newImport]="false"></app-header>

<div id="content">
	<h2>Upload a .csv file</h2>
	<link href="https://fonts.googleapis.com/icon?family=Material+Icons"
		  rel="stylesheet">

	<div class="checkbox">
		<input type="checkbox"
			   id="headerPresence"
			   (change)="checkChange($event)"
			   [(ngModel)]="hasHeaderChecked">
		<label for="headerPresence">Presence of a title line</label>
	</div>

	<div class="btn btn-primary btn-file"
		 data-target="#txtFileUpload">
		<span class="material-icons">backup</span>&nbsp;&nbsp;Choose a file...
		<input type="file"
			   #myInput
			   name="Upload CSV"
			   id="txtFileUpload"
			   accept=".csv"
			   (change)="uploadListener($event)" />
	</div>

	<button class="buttonHelp"
			data-toggle="modal"
			data-target="#supportModal">
		<span class="material-icons support">
			help
		</span>
	</button>

	<!-- Modal -->
	<div class="modal fade"
		 id="supportModal"
		 tabindex="-1"
		 role="dialog"
		 aria-labelledby="supportModalLabel"
		 aria-hidden="true">
		<div class="modal-dialog modal-lg"
			 role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title"
						id="supportModalLabel">Support</h5>
					<button type="button"
							class="close"
							data-dismiss="modal"
							aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<ul>
						<li>This tool will help you visualize your needs in terms of quantity.</li>
						<hr>
						<li>If the first line of your file is a title line, please check the box.</li>
						<hr>
						<li>The file must be a .csv and the requested format is : <br>
							Event ; Room ; Power ; Stock withdrawal date ; Back in stock date
							<br /><br /><i>(date format = yyyy-mm-dd)</i>
						</li>
					</ul>
				</div>
				<div class="modal-footer">
					<button type="button"
							class="btn btn-primary"
							data-dismiss="modal">Close</button>
				</div>
			</div>
		</div>
	</div>
</div>