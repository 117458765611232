import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from '../services/cookie.service';
import { DataService } from '../services/data.service';

@Component({
	selector: 'app-csv-file-import',
	templateUrl: './csv-file-import.component.html',
	styleUrls: ['./csv-file-import.component.scss']
})
export class CsvFileImportComponent implements OnInit {

	//Utilisé pour calculer le temps de téléchargement
	public date_onloadstart: number;
	public date_onloadend: number;
	public fileName: string;
	public hasHeaderChecked: boolean;
	@ViewChild('myInput')
	myInput: ElementRef;

	constructor(private router: Router,
		private dataService: DataService,
		private cookieService: CookieService) { }

	ngOnInit(): void {
		this.hasHeaderChecked = Boolean(this.cookieService.getCookie('hasHeaderChecked'));
		this.dataService.resetData();
	}

	public checkChange(values: any) {
		this.cookieService.setCookie('hasHeaderChecked', String(this.hasHeaderChecked));
	}

	uploadListener($event: any): void {
		let files = $event.srcElement.files;
		if (this.isValidCSVFile(files[0])) {
			this.dataService.showHeaderSpinner();
			let input = $event.target;  //??
			this.fileName = input.files[0].name;
			let reader = new FileReader();

			reader.readAsText(input.files[0]);  //Ctrl+Click => library. readAsText est une fonction asynchrone
			this.myInput.nativeElement.value = ""; //effacer le nom de fichier dans le formulaire 

			reader.onloadstart = () => {
				this.date_onloadstart = Date.now();
			}

			reader.onload = () => {
				this.dataService.computeFile(this.fileName, <string>reader.result, this.hasHeaderChecked)
					.then(() => {
						this.date_onloadend = Date.now();
						console.log("Upload time = ", this.date_onloadend - this.date_onloadstart, " ms");
						this.dataService.hideHeaderSpinner();
						this.router.navigate(['overview']);
					})
					.catch((err) => {
						console.error(err);
						alert(err);
						this.dataService.hideHeaderSpinner();
						this.router.navigate(['']);
					});
			};

			reader.onerror = () => {
				console.log('error is occured while reading file!');
				this.dataService.hideHeaderSpinner();
			};
		} else {
			alert("Please import valid .csv file.");
		}
	}

	isValidCSVFile(file: any) {
		return file.name.endsWith(".csv");
	}
}
