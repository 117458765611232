import { Injectable } from '@angular/core';
import { Chart } from 'node_modules/Chart.js';
import { BehaviorSubject } from 'rxjs';
import { cumulConfigTri } from 'src/app/models/cumulConfigTri.model';
import { ConfigTri } from '../../models/ConfigTri.model';

@Injectable({
	providedIn: 'root'
})
export class ConfigDataService {

	public nbOfDaysSubject = new BehaviorSubject<number>(0); //Nb de jours sur la période choisie par l'utilisateur
	public datesChangeSubject = new BehaviorSubject<number>(0);
	public date1Subject = new BehaviorSubject<any>(0); //date de début de période
	public DatesSubject = new BehaviorSubject<string[]>([]);

	public commandSubject = new BehaviorSubject<number[]>([]);

	public tabConfigTriSubject = new BehaviorSubject<cumulConfigTri[]>([]);
	public client_configsSubject = new BehaviorSubject<ConfigTri[]>([]);
	public configArraySubject = new BehaviorSubject<ConfigTri[]>([]);
	public pieChart_configsSubject = new BehaviorSubject<ConfigTri[]>([]);

	public graphConfigCumulSubject = new BehaviorSubject<Chart>(0);
	public Config_PieChartSubject = new BehaviorSubject<Chart>(0);

	public oldhideButtonSubject = new BehaviorSubject<boolean>(false);
	public hideButtonSubject = new BehaviorSubject<boolean>(undefined);
	public hideConfig_indSubject = new BehaviorSubject<number>(0);

	public spliceConfigSubject = new BehaviorSubject<ConfigTri>(undefined);

	public coveringPercentageSubject = new BehaviorSubject<number>(0);


	constructor() { }

	getArrayCopy(copied: any[]) {
		let copy: any[] = [];

		for (let i = 0; i < copied.length; i++) {
			copy[i] = Object.assign({}, copied[i]);
		}
		return copy;
	}
}