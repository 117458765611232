import { Component, OnInit } from '@angular/core';
import { Chart } from 'node_modules/Chart.js';
import { ConfigTri } from '../models/ConfigTri.model';
import { cumulConfigTri } from '../models/cumulConfigTri.model';
import { DataService } from '../services/data.service';
import { ConfigDataService } from './services/config-data.service'; //DataService du projet

@Component({
	selector: 'app-analyse-config',
	templateUrl: './analyse-config.component.html',
	styleUrls: ['./analyse-config.component.scss']
})
export class AnalyseConfigComponent implements OnInit {

	nbOfDays: number;
	datesChange: number;
	date1: Date;
	tabConfigTri: cumulConfigTri[];
	configArray: ConfigTri[];
	client_configs: ConfigTri[];
	Dates: string[];
	command: number[];
	pieChart_configs: ConfigTri[];
	Config_PieChart: Chart;
	boolShowGraphCumul: boolean;
	oldhideButton: boolean;
	hideButton: boolean;
	spliceConfig: ConfigTri;


	constructor(private configdataService: ConfigDataService,
		private dataService: DataService) {
		this.configdataService.nbOfDaysSubject.asObservable().subscribe(
			data => this.nbOfDays = data
		);
		this.configdataService.datesChangeSubject.asObservable().subscribe(
			data => this.datesChange = data
		);
		this.configdataService.date1Subject.asObservable().subscribe(
			data => this.date1 = data
		);
		this.configdataService.tabConfigTriSubject.asObservable().subscribe(
			data => this.tabConfigTri = data
		);
		this.configdataService.configArraySubject.asObservable().subscribe(
			data => this.configArray = data
		);
		this.configdataService.DatesSubject.asObservable().subscribe(
			data => this.Dates = data
		);
		this.configdataService.commandSubject.asObservable().subscribe(
			data => this.command = data
		);
		this.configdataService.pieChart_configsSubject.asObservable().subscribe(
			data => this.pieChart_configs = data
		);
		this.configdataService.client_configsSubject.asObservable().subscribe(
			data => this.client_configs = data
		);
		this.configdataService.Config_PieChartSubject.asObservable().subscribe(
			data => this.Config_PieChart = data
		);
		this.configdataService.oldhideButtonSubject.asObservable().subscribe(
			data => this.oldhideButton = data
		);
		this.configdataService.hideButtonSubject.asObservable().subscribe(
			data => this.hideButton = data
		);
		this.configdataService.spliceConfigSubject.asObservable().subscribe(
			data => this.spliceConfig = data
		);
	}

	ngOnInit() {
		this.boolShowGraphCumul = false;
	}

	showGraphCumul() {
		this.boolShowGraphCumul = true;
	}

	showPie() {
		this.boolShowGraphCumul = false;
	}




}
