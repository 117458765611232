import { Component, Input, OnChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { cumulConfigTri } from 'src/app/models/cumulConfigTri.model';
import { ConfigTri } from '../../models/ConfigTri.model';
import { DataService } from '../../services/data.service';
import { ConfigDataService } from '../services/config-data.service';

@Component({
	selector: 'app-config-form',
	templateUrl: './config-form.component.html',
	styleUrls: ['./config-form.component.scss']
})
export class ConfigFormComponent implements OnChanges {

	records_sorted: any[];

	configForm: FormGroup;
	configs: FormArray;

	client_configs: ConfigTri[];

	@Input() spliceConfig?: ConfigTri;

	tabConfigTri?: cumulConfigTri[];
	Dates?: string[];
	command?: number[];

	covering_percentage: number;

	constructor(private formBuilder: FormBuilder, private dataService: DataService, private configdataService: ConfigDataService) {
		this.dataService.currentrecords_sorted.subscribe(
			tab_records_sorted => this.records_sorted = tab_records_sorted
		);
		this.configdataService.tabConfigTriSubject.asObservable().subscribe(
			data => this.tabConfigTri = data
		);
		this.configdataService.DatesSubject.asObservable().subscribe(
			data => this.Dates = data
		);
		this.configdataService.commandSubject.asObservable().subscribe(
			data => this.command = data
		);
		this.configdataService.spliceConfigSubject.asObservable().subscribe(
			data => this.spliceConfig = data
		);
		this.configdataService.coveringPercentageSubject.asObservable().subscribe(
			covering_percentage => this.covering_percentage = covering_percentage
		);
	}

	ngOnChanges() {
		console.log("configForm=", this.configForm)
		if (this.configForm == undefined) {
			console.log("form init");
			this.initForm();
		}
		else {
			console.log("splice config");
			this.getSpliceForm(this.spliceConfig);
		}

	}

	initForm() {
		this.configForm = this.formBuilder.group({
			configs: this.formBuilder.array([this.createConfig()]),
		});

	}

	//get f() { return this.configForm.controls; }

	/*
	getSpliceForm(){ //Utilisé pour faire disparaître de la page le form d'une config qui ne couvre pas le graph davantage
	//Cycle : 
	//1. Une confif testée est ajoutée à client_configs dans ce component
	//2. La config passe dans graph-cumul : si elle ne couvre pas le graph davantage, elle est supprimé de client_configs
	//3. Ce component étant dépendant des changements de client_configs, getSpliceForm se lancera 
	  for (let i=0; i<this.configForm.value.configs.length; i++){
		let config_find:boolean = false;
		for (let j=0; j<this.client_configs.length; j++){
		  if (this.configForm.value.configs[i].tri16A == this.client_configs[j].tri16A
			&&this.configForm.value.configs[i].tri32A == this.client_configs[j].tri32A
			&&this.configForm.value.configs[i].tri63A == this.client_configs[j].tri63A){
			  config_find = true;
		  }
		} 
		if (config_find == false){ //Si la config du form i n'apparaît pas dans client_configs
		  console.log("splice form=",this.configForm.get('configs')['controls'].splice(i,1));
		  this.configForm.get('configs')['controls'].splice(i,1);
		} 
	  }  
	}
	*/

	getSpliceForm(config: ConfigTri) {
		for (let i = 0; i < this.configForm.value.configs.length; i++) {
			if (this.configForm.value.configs[i].tri16A == config.tri16A
				&& this.configForm.value.configs[i].tri32A == config.tri32A
				&& this.configForm.value.configs[i].tri63A == config.tri63A) {
				console.log("splice form=", this.configForm.get('configs')['controls'].splice(i, 1));
				this.configForm.get('configs')['controls'].splice(i, 1);
			}
		}
	}

	getClientConfigs() {
		this.client_configs = []; //Recalcule client_configs à chaque validation de nouvelles config

		for (let i = 0; i < this.configForm.value.configs.length; i++) {

			let config_tested: boolean = false;

			for (let j = 0; j < this.client_configs.length; j++) {

				if (this.client_configs[j].tri16A == this.configForm.value.configs[i].tri16A
					&& this.client_configs[j].tri32A == this.configForm.value.configs[i].tri32A
					&& this.client_configs[j].tri63A == this.configForm.value.configs[i].tri63A) {
					config_tested = true; //La config i a déjà été entrée

					this.configForm.get('configs')['controls'].splice(i, 1);
					this.configForm.value.configs.splice(i, 1); //Supprime le formulaire de cette config
					break; //Sort de la boucle for 
				}

			}

			if (config_tested == false) { //Si la config i n'a pas encore été entrée

				let client_config = new ConfigTri();
				client_config.tri16A = this.configForm.value.configs[i].tri16A;
				client_config.tri32A = this.configForm.value.configs[i].tri32A;
				client_config.tri63A = this.configForm.value.configs[i].tri63A;
				client_config.deleteButton = 'deleteButton' + i;
				//client_config.overequipButton = 'overequipButton' + i;
				client_config.hideButton = false;

				this.client_configs.push(client_config);
			}
			else {
				i = i - 1;
			}

		}

		console.log("this.configForm.value.configs=", this.configForm.value.configs);
		this.configdataService.client_configsSubject.next(this.client_configs);
		console.log("client configs=", this.client_configs);

	}

	getDeleteConfig(deleteButton_id: string) {

		let newclient_configs: ConfigTri[] = [];
		//this.client_configs = [];
		let forms = this.configForm.get('configs')['controls'];
		for (let i = 0; i < forms.length; i++) {
			let form = document.getElementById('form' + i).children;
			let button = form[0].children[0];


			if (button.id == deleteButton_id) {

				for (let j = 0; j < this.client_configs.length; j++) {
					if (this.client_configs[j].tri16A == this.configForm.value.configs[i].tri16A
						&& this.client_configs[j].tri32A == this.configForm.value.configs[i].tri32A
						&& this.client_configs[j].tri63A == this.configForm.value.configs[i].tri63A) {

						this.client_configs.splice(j, 1);
						j = j - 1;
					}
					else {
						newclient_configs.push(this.client_configs[j]); //utiliser uniquement splice empêche ngOnChanges dans graph_cumul de détecter la modif de this.client_configs  
					}
				}

				this.configForm.get('configs')['controls'].splice(i, 1);
			}

		}

		this.configdataService.client_configsSubject.next(newclient_configs);
		console.log("client configs=", this.client_configs);
	}


	createConfig(): FormGroup {
		return this.formBuilder.group({
			tri16A: [0, [Validators.required, Validators.min(0), Validators.maxLength(2)]],
			tri32A: [0, [Validators.required, Validators.min(0), Validators.maxLength(2)]],
			tri63A: [0, [Validators.required, Validators.min(0), Validators.maxLength(2)]],
		})
	}

	addConfig(): void {
		this.configs = this.configForm.get('configs') as FormArray;
		this.configs.push(this.createConfig());
	}

	hideConfig(action: string, ind: number) {
		if (action == 'hide') { //Si l'utilisateur click sur l'oeil ouvert -> on cache la config du graph
			//console.log("action=",action);

			this.configdataService.oldhideButtonSubject.next(this.client_configs[ind].hideButton);
			this.client_configs[ind].hideButton = true;

			//console.log("oldhideButton=",this.configdataService.oldhideButtonSubject);
			//console.log("hideButton=",this.configdataService.hideButtonSubject);

			this.configdataService.hideButtonSubject.next(this.client_configs[ind].hideButton);
			this.configdataService.hideConfig_indSubject.next(ind);
		}
		else { //Si l'utilisateur click sur l'oeil fermé -> on fait apparaître la config sur le graph
			//console.log("action=",action);

			this.configdataService.oldhideButtonSubject.next(this.client_configs[ind].hideButton);
			this.client_configs[ind].hideButton = false;

			//console.log("oldhideButton=",this.configdataService.oldhideButtonSubject);
			//console.log("hideButton=",this.configdataService.hideButtonSubject);

			this.configdataService.hideButtonSubject.next(this.client_configs[ind].hideButton);
			this.configdataService.hideConfig_indSubject.next(ind);
		}
		//console.log("AFTER HIDE client_configs=",this.client_configs);
		this.configdataService.client_configsSubject.next(this.client_configs);
	}

}


