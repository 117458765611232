import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { cumulConfigTri } from 'src/app/models/cumulConfigTri.model';
import { DataService } from '../../services/data.service'; //DataService du projet
import { ConfigDataService } from '../services/config-data.service'; //DataService de analyse-config

@Component({
	selector: 'app-period-form',
	templateUrl: './period-form.component.html',
	styleUrls: ['./period-form.component.scss']
})
export class PeriodFormComponent implements OnInit {

	records_sorted: any[];

	datesForm: FormGroup;

	nbOfDays: number = 0;

	default_dateDebut: string;
	default_dateFin: string;

	datesChange: number = 0;

	constructor(private formBuilder: FormBuilder, private dataService: DataService, private configdataService: ConfigDataService) {
		this.dataService.currentrecords_sorted.subscribe(
			tab_records_sorted => this.records_sorted = tab_records_sorted
		);
	}

	ngOnInit() {
		this.initForm();
		this.getNbOfDays(this.records_sorted[0].debutDate, this.records_sorted[this.records_sorted.length - 1].debutDate);
	}

	initForm() {
		this.getDefaultPeriod();

		this.datesForm = this.formBuilder.group({
			date1: [this.default_dateDebut, [Validators.required]],
			date2: [this.default_dateFin, [Validators.required]]
		});
	}

	getDefaultPeriod() {
		let monthBegin = (this.records_sorted[0].debutDate.getMonth() + 1).toString();
		let monthEnd = (this.records_sorted[this.records_sorted.length - 1].debutDate.getMonth() + 1).toString();
		let dayBegin = this.records_sorted[0].debutDate.getDate().toString();
		let dayEnd = this.records_sorted[this.records_sorted.length - 1].debutDate.getDate().toString();

		if (+monthBegin < 10) {
			monthBegin = "0" + monthBegin;
		}
		if (+monthEnd < 10) {
			monthEnd = "0" + monthEnd;
		}
		if (+dayBegin < 10) {
			dayBegin = "0" + dayBegin;
		}
		if (+dayEnd < 10) {
			dayEnd = "0" + dayEnd;
		}

		this.default_dateDebut = this.records_sorted[0].debutDate.getFullYear() + '-' + monthBegin + '-' + dayBegin;
		this.default_dateFin = this.records_sorted[this.records_sorted.length - 1].debutDate.getFullYear() + '-' + monthEnd + '-' + dayEnd;
	}


	getNbOfDays(date1: Date, date2: Date) { //Calcul Nb de jours sur la période
		this.nbOfDays = 0;
		let dateDebut = new Date(date1);
		let dateFin = new Date(date2);
		dateDebut.setHours(0); dateFin.setHours(0);

		//TimezoneOffset==-60 correspond à GMT+1 (Heure normale d'Europe centrale)
		//TimezoneOffset==-120 correspond à GMT+2 (Heure d'été d'Europe centrale)
		//Pour le calcul du nombre de jours : on met dateDebut et dateFin sur le même fuseau horaire GMT+1 :
		if (dateDebut.getTimezoneOffset() == -120) { //geTimezoneOffset retourne la différence, exprimée en minutes, entre les fuseaux horaires UTC et celui de l'heure locale.
			dateDebut.setMinutes(dateDebut.getMinutes() + 60); //On ne peut pas modifier le fuseau directement
		}
		if (dateFin.getTimezoneOffset() == -120) {
			dateFin.setMinutes(dateFin.getMinutes() + 60);
		}
		let diffDate = dateFin.getTime() - dateDebut.getTime(); //diffDate est en ms
		this.nbOfDays = (diffDate / (1000 * 60 * 60 * 24));

		if (this.nbOfDays < 0) {
			alert("Période invalide : Vous avez rentré une Date de fin ultérieure à la Date de début.")
		}
		if (this.nbOfDays == 0) {
			alert("Période invalide : Veuillez rentrer une période de minimum 2 jours.")
		}

		this.configdataService.nbOfDaysSubject.next(this.nbOfDays);

		this.getDates();
	}

	getDates() { //Crée les vecteurs Dates et tabConfigTri
		let tabConfigTri: cumulConfigTri[] = []; //Ici, on rempli le paramètre "date" des éléments de tabConfigTri
		let Dates: string[] = [];
		let dateDebut = new Date(this.datesForm.value.date1);

		for (let i = 0; i < this.nbOfDays; i++) {
			let newdate = new Date();
			newdate = dateDebut;
			newdate.setDate(dateDebut.getDate() + i);

			let CumulConfigTri = new cumulConfigTri;
			CumulConfigTri.date = newdate;
			CumulConfigTri.config = [];
			tabConfigTri.push(CumulConfigTri);  //tabConfigTri est trié par ordre chronologique

			Dates.push(CumulConfigTri.date.getDate() + '/' + (CumulConfigTri.date.getMonth() + 1) + '/' + CumulConfigTri.date.getFullYear());

			dateDebut = new Date(this.datesForm.value.date1);
		}

		this.configdataService.DatesSubject.next(Dates);
		this.configdataService.tabConfigTriSubject.next(tabConfigTri);
	}

	getDatesChange() {
		this.datesChange += 1;
		this.configdataService.datesChangeSubject.next(this.datesChange);
		this.configdataService.date1Subject.next(this.datesForm.value.date1);

	}

}
